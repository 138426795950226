import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss']
})
export class IntroComponent implements OnInit {

  title = "Alyse Larkin, PhD"
  subtitle = "Microbial Ecology, Biological Oceanography, & STEM Pedagogy"

  constructor() { }

  ngOnInit() {
  }

}
